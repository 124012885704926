@import './../_variables.scss';

.bookingCard {
  margin-top: 20px;
  width: 100%;
  max-width: $breakpoint-tablet;
  display: flex;
  padding: 20px 16px;
  color: $color-dark;
  background-color: $color-white;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  align-items: center;
  .cardHeader {
    .bookingIdWrapper {
      display: flex;
      align-items: center;
      .bookingStatus {
        margin-right: 4px;
        text-transform: capitalize;
        padding: 2px 8px;
        border-radius: 8px;
        width: fit-content;
        color: $color-white;
        &.pending {
          background-color: $color-warning;
        }
        &.cancelled {
          background-color: $color-danger;
        }
        &.confirmed {
          background-color: $color-success;
        }
        &.inprogress {
          background-color: $color-info;
        }
        &.completed {
          background-color: $color-primary;
        }
      }
      .bookingName {
        font-size: 18px;
        font-weight: bold;
        .bookingText {
          @media screen and (max-width: $breakpoint-mobile){
            display: none;
          }
        }
      }
    }
    .bookingTime {
      font-size: 12px;
      font-style: italic;
      margin-bottom: 4px;
    }
    .buttonWrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      button {
        margin: 4px 4px 0 0;
      }
    }
  }
  .bookingDetail {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .bookingInfo {
      flex: 1;
      .bookingDescription {
        padding: 0 16px;
        width: 100%;
        .customerContact {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      align-items: center;
      display: flex;
    }
  }
}