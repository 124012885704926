@import "/src/styles/_variables.scss";

.loginPage {
  margin: 0 auto;
  padding: 0 2rem;
  width: 100%;
  max-width: $breakpoint-desktop;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .brandWrapper {
    margin-top: 40px;
    .brandTitle {
      font-size: 40px;
      font-weight: 400;
    }
    .brandSubtitle {
      margin-top: 4px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.1rem;
      color: $color-black;
    }
    .brandDescription {
      margin-top: 20px;
      font-size: 14px;
      letter-spacing: 0.03rem;
    }
  }
  .loginWrapper {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 350px;
    align-items: center;
    background-color: $color-white;
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin: 40px 0 0;
    padding: 20px 16px 28px;
    input {
      margin: 6px 0 16px;
      width: 90%;
    }
    .forgotButton {
      width: 90%;
      text-align: center;
      padding: 16px 0 22px;
      margin-bottom: 26px;
      // border-bottom: 1px solid $text-color-1;
      color: $color-dark;
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
