@import "../_variables.scss";

.menuBar {
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  .businessInfo {
    text-align: center;
    .businessTitle {
      padding: 10px 0;
      cursor: pointer;
      img {
        height: 80px;
        width: auto;
      }
    }
    .businessSubtitle {
      margin-top: -30px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.1rem;
    }
  }
  .barsMenu {
    all: unset;
    display: none;
  }
}

@media (max-width: $breakpoint-tablet) {
  .menuBar {
    .rightMenu {
      display: none;
    }
    .barsMenu {
      display: block;
    }
  }
}
