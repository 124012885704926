@import './../_variables.scss';

.staffCard {
  margin-top: 20px;
  width: 100%;
  max-width: $breakpoint-tablet;
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  background-color: $color-white;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  align-items: center;
  .staffInfo {
    display: flex;
    align-items: center;
    .staffProfilePicture {
      height: 160px;
      width: 160px;
      margin: 0 32px 0 16px;
      object-fit: cover;
      border-radius: 100%;
    }
    .staffDescription {
      align-self: flex-start;
      .staffName {
        font-size: 18px;
        font-weight: bold;
      }
      .staffRole {
        font-size: 12px;
        font-style: italic;
        margin-bottom: 8px;
      }
    }
  }
  .deleteButton {
    align-self: center;
    &:hover {
      cursor: pointer;
    }
  }
}


@media screen and (max-width: $breakpoint-mobile) {
  .staffCard {
    .staffInfo {
      .staffProfilePicture {
        height: 100px;
        width: 100px;
      }
    }
  }
}