//breakpoint
$breakpoint-desktop: 1366px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 576px;
$breakpoint-m-mobile: 375px;
$breakpoint-sm-mobile: 320px;

//color
$color-primary: #4e73df;
$color-success: #1cc88a;
$color-info: #36b9cc;
$color-danger: #e74a3b;
$color-warning: #f7b50e;
$color-secondary: #858796;
$color-light: #f8f9fc;
$color-dark: #5a5c69;
$color-white: #ffffff;
$color-black: #000000;
//color-hightlight
$color-primary-hightlight: #3C5EC1;
$color-success-hightlight: #40a983;