@import './../_variables.scss';

.serviceCard {
  margin-top: 20px;
  width: 100%;
  max-width: $breakpoint-tablet;
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  background-color: $color-white;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  align-items: center;
  .serviceInfo {
    .serviceDescription {
      padding: 0 16px;
      .serviceName {
        font-size: 18px;
        .serviceType {
          font-weight: bold;
        }
      }
      .serviceDuration {
        font-size: 12px;
        font-style: italic;
        margin-bottom: 8px;
      }
      .servicePrice {
        margin-top: 8px;
        font-size: 16px;
      }
    }
    align-items: center;
    display: flex;
  }
}


@media screen and (max-width: $breakpoint-mobile) {
  .serviceCard {
    .serviceInfo {
      .serviceProfilePicture {
        height: 100px;
        width: 100px;
      }
    }
  }
}