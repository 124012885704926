@import './../_variables.scss';

.accountCard {
  margin-top: 20px;
  width: 100%;
  max-width: $breakpoint-tablet;
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  background-color: $color-white;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  align-items: center;
  .accountInfo {
    display: flex;
    align-items: center;
    .customerDescription {
      margin-left: 16px;
      align-self: flex-start;
      .customerName {
        font-size: 18px;
        font-weight: bold;
        word-break: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
      }
      .customerAccount {
        font-size: 14px;
      }
    }
  }
  .deleteButton {
    align-self: center;
    &:hover {
      cursor: pointer;
    }
  }
}

.editRoleModal {
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  .selectRole {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
}