@import "/src/styles/_variables.scss";

.bookingPage {
  padding: 0 2rem;
  width: 100%;
  margin: 0 auto;
  max-width: $breakpoint-desktop;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .infoTitle {
    font-size: 20px;
    font-weight: bold;
  }
  .customButton {
    margin-bottom: 30px;
  }
  .noResult {
    margin: 30px 0;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 1200px;
    button {
      margin: 20px;
    }
  }
  .pagination {
    margin-top: 20px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .bookingPage {
    padding: 0 1rem;
    .header {
      button {
        margin: 10px;
      }
    }
  }
}
