@import '/src/styles/_variables.scss';

.ant-drawer-body {
  background-color: $color-light;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
  transition: none !important;
}
.ant-menu {
  background: none;
  border: none;
  .ant-menu-item {
    color: $color-dark;
    .ant-menu-title-content > a,  .ant-menu-title-content > div {
      &:hover {
        color: $color-primary;
        border-color: transparent;
      }
    }
    &.ant-menu-item-selected, &.ant-menu-item-selected a {
      color: $color-primary !important;
      background-color: unset;
    }
  }
}
.ant-menu-item.language-menu {
  color: $color-dark !important;
  &:hover {
    color: $color-dark !important;
  }
  .flag {
    height: 18px;
    border: 1px solid $color-light;
    &.selected {
      border: 1px solid $color-dark;
    }
  }
}

