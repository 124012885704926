@import '/src/styles/_variables.scss';

.serviceCreatePage {
  padding: 0 2rem;
  width: 100%;
  margin: 0 auto;
  max-width: $breakpoint-desktop;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .serviceInfo {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .serviceDescription {
      width: 600px;
      display: flex;
      flex-direction: column;
      .infoTitle {
        font-size: 16px;
        font-weight: bold;
      }
      input:not([type='checkbox']) {
        margin: 6px 0 16px;
        width: initial;
      }
      select {
        margin: 6px 0 16px;
        width: unset;
      }
      .durationWrapper {
        margin: 6px 0 16px;
        display: flex;
        width: 95%;
        align-items: baseline;
        justify-content: space-around;
        input.durationInput {
          margin: 0 10px;
          width: 30%;
        }
      }
    }
  }
  .buttonWrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    button {
      margin: 10px;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .serviceCreatePage {
    padding: 0 1rem;
  }
} 