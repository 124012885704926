@import '/src/styles/_variables.scss';

.confirmModal {
  .modalTitle {
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .buttonWrapper {
    margin-top: 26px;
    display: flex;
    justify-content: space-around;
    button {
      margin: 0 10px;
    }
  }
}