@import './../_variables.scss';

.customButton {
  all: unset;
  width: 226px;
  padding: 12px 6px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 300;
  border-radius: 8px;
  //primary theme
  &.primary {
    border: 1px solid $color-primary;
    background-color: $color-primary;
    color: $color-white;
    &:hover {
      cursor: pointer;
      background-color: $color-primary-hightlight;
    }
  }
  //white theme
  &.white {
    border: 1px solid $color-primary;
    background-color: $color-white;
    color: $color-primary;
    &:hover {
      cursor: pointer;
      background-color: $color-primary;
      color: $color-white;
    }
  }
  //success theme
  &.success {
    border: 1px solid $color-success;
    background-color: $color-success;
    color: $color-white;
    &:hover {
      cursor: pointer;
      background-color: $color-success-hightlight;
    }
  }
  //info theme
  &.info {
    border: 1px solid $color-info;
    background-color: $color-info;
    color: $color-white;
    &:hover {
      cursor: pointer;
      background-color: $color-info;
    }
  }
  //danger theme
  &.danger {
    border: 1px solid $color-danger;
    background-color: $color-danger;
    color: $color-white;
    &:hover {
      cursor: pointer;
      background-color: $color-danger;
      color: $color-white;
    }
  }
  //warning theme
  &.warning {
    border: 1px solid $color-warning;
    background-color: $color-warning;
    color: $color-white;
    &:hover {
      cursor: pointer;
      background-color: $color-warning;
    }
  }
  &.disabled {
    opacity: 0.5;
    &:hover {
      cursor: not-allowed;
      background-color: inherit;
    }
  }
}