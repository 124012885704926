@import '/src/styles/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

.App {
  width: 100%;
  margin: 0;
  padding-bottom: 40px;
  font-family: Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: $color-light;
  color:  $color-dark;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:not([type='checkbox']) {
  all: unset;
  width: 202px;
  padding: 12px;
  font-size: 12px;
  font-weight: 300;
  background-color: transparent;
  border: 1px solid $color-dark;
  border-radius: 8px;
}

select {
  width: 202px;
  padding: 12px;
  font-size: 12px;
  font-weight: 300;
  background-color: transparent;
  border: 1px solid $color-dark;
  border-radius: 8px;
  &:focus, &:active {
    outline: none;
  }
}

.editButton {
  &:hover {
    cursor: pointer;
  }
}

.requiredFieldIcon {
  color: $color-danger;
}

.tab {
  width: 100%;
  max-width: 768px;
  overflow: hidden;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  background-color: $color-light;
  display: flex;
  justify-content: space-around;
  .tabButton {
    flex: 1;
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @media screen and (max-width: $breakpoint-mobile) {
      flex-direction: column;
      align-items: center;
    }
    img {
      height: 20px;
      width: auto;
    }
    &:hover, &.active {
      color: $color-primary;
      font-weight: bold;
      img {
        filter: invert(37%) sepia(88%) saturate(492%) hue-rotate(187deg) brightness(100%) contrast(90%);
      }
    }
  }
}

.ant-modal-content {
  border-radius: 8px;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}