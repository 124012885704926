@import '/src/styles/_variables.scss';

.feedbackCreatePage {
  padding: 0 2rem;
  width: 100%;
  margin: 0 auto;
  max-width: $breakpoint-desktop;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .feedbackInfo {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .infoTitle {
      font-size: 16px;
      font-weight: bold;
    }
    .profilePictureUpload,
    .feedbackDescription {
      display: flex;
      flex-direction: column;
    }
    .profilePictureUpload {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 16px;
      .previewPhoto {
        height: 300px;
        width: 300px;
        margin-bottom: 20px;
        object-fit: cover;
      }
      button {
        position:relative;
        .uploadInput {
          position: absolute;
          z-index: 1000;
          opacity: 0;
          cursor: pointer;
          right: 0;
          top: 0;
          height: 100%;
          font-size: 24px;
          width: 100%;
        }
      }
      .profilePictureHint {
        margin-top: 6px;
        font-style: italic;
      }
    }
    .feedbackDescription {
      width: 600px;
      input:not([type='checkbox']) {
        margin: 6px 0 16px;
        width: initial;
      }
      select {
        margin: 6px 0 16px;
        width: unset;
      }
      .durationWrapper {
        margin: 6px 0 16px;
        display: flex;
        width: 95%;
        align-items: baseline;
        justify-content: space-around;
        input.durationInput {
          margin: 0 10px;
          width: 30%;
        }
      }
      form.serviceList {
        margin: 0 0 16px;
      }
    }
  }
  .buttonWrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    button {
      margin: 10px;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .feedbackCreatePage {
    padding: 0 1rem;
  }
}