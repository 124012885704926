@import '/src/styles/_variables.scss';

.staffPage {
  padding: 0 2rem;
  width: 100%;
  margin: 0 auto;
  max-width: $breakpoint-desktop;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .pagination {
    margin-top: 20px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .staffPage {
    padding: 0 1rem;
  }
}