@import './../_variables.scss';

.feedbackCard {
  margin-top: 20px;
  width: 100%;
  max-width: $breakpoint-tablet;
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  background-color: $color-white;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  align-items: center;
  .feedbackInfo {
    display: flex;
    align-items: center;
    .customerProfilePicture {
      height: 160px;
      width: 160px;
      margin: 0 32px 0 16px;
      object-fit: cover;
      border-radius: 100%;
    }
    .customerDescription {
      align-self: flex-start;
      .customerName {
        font-size: 18px;
        font-weight: bold;
      }
      .customerFeedback {
        font-size: 14px;
      }
    }
  }
  .deleteButton {
    align-self: center;
    &:hover {
      cursor: pointer;
    }
  }
}


@media screen and (max-width: $breakpoint-mobile) {
  .feedbackCard {
    .feedbackInfo {
      .customerProfilePicture {
        height: 100px;
        width: 100px;
      }
    }
  }
}